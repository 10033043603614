.scope-switch
  margin 0
  position relative;
  right 20px
  padding 0
  top -10px
  width 50px
  list-style none
  li
    padding 10px 40px 10px 30px
    background transparent
    transition background 0.5s ease
    &:hover
      background rgba(238,2,66,0.90)
  li:before
    content "\f0dd"
    top 7px
    margin-left -15px
    position absolute
    font-family FontAwesome
  ul
    opacity 0
    transition opacity 0.5s ease
    margin 0
    padding 0
    padding-top 10px
    list-style none
    li
      padding 3px 0
      &:before
        content ""
      a
        color white
  &:hover
    cursor pointer
    ul
      opacity 1