html
    +below('s')
        font-size 90%

body
    font-family $font-family-sans-serif;
    font-weight 300
    font-size 16px
    color #333
    line-height 1.8
    // lost-utility edit

a
    color primary-color
    text-decoration none

hr
    border none
    border-bottom 1px solid #ddd

.fa
    display inline-block

strong
    font-weight normal
    font-weight 600

.arrow-down
    width: 0;
    height: 0;
    border-left: 12px solid transparent;
    border-right: 12px solid transparent;
    border-top: 12px solid #f00;
    position absolute
    transform: translateX(-50%);
    top 0
    left 50%