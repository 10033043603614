.cta
    background #1ab9ec
    padding 1.5em 0 3em
    font-size 1em
    position relative
    h3
        font-weight 300
        font-size 2em
        color #fff
    .arrow-down
        border-top-color white
    &__block
        lost-column 1/4 4 15px
        +below('m')
            lost-column 1/2 2 15px
        +below('s')
            lost-column 1/1 1 15px
        padding 25px 20px 30px
        background white
        position relative
        transition transform 0.5s ease
        margin-bottom 1.25em
        &:active
            transform scale(.96)
        a, a:visited
            color inherit
        &__title
            font-weight 700
            padding-bottom 0.20rem
            text-transform uppercase
        &__text
            font-size 0.8em
        &__button
            position absolute
            background #ee0242
            border-style: solid;
            border-width: 3px 0 0 3px;
            border-color #1ab9ec
            color white
            font-weight 600
            font-size 0.9em
            text-transform uppercase
            padding 1px 15px
            right 0
            bottom 0

