@import 'bootstrap/forms'

.form-group
    label
        margin-bottom 2px
        font-weight 500

    &.has-error label
        color $state-danger-text

        .inline-error
            background $state-danger-text
            color $state-danger-bg
            display inline-block
            padding 4px 6px
            line-height 1
            font-size .8em
            border-radius 4px
            margin-left 10px
            margin-bottom 1px


.form-control:focus
    box-shadow inset 0 1px 1px rgba(0,0,0,.075), 0 0 3px rgba(64,72,85,.6)

.bs-callout
    padding 10px
    margin 20px 0
    border 1px solid #eee
    border-left-color #1b809e
    border-left-width 5px
    border-radius 3px