.news-list
    lost-utility clearfix

.news
    margin-bottom 3em
    overflow auto
    clear both
    border 1px solid #eeeeee
    padding 1.5rem

    a
        color inherit

    &__title
        margin-top 0
        margin-bottom 0
        font-size 1rem
        font-weight 400

    &__meta
        color #666
        font-weight 300
        font-size 0.8rem
        margin-bottom 1em
        margin-top 0

    &__text
        lost-column 1/1
        font-size 0.9rem

    &__button
        right 0
        bottom 0
        position absolute
