.header
    .nav-main
        lost-column 7/12 3
        +below('m')
            padding-top 20px
            lost-column 1/4 2
        +below('s')
            padding-top 8px
            lost-column 1/3 2

        .nav-items
            +below('l')
                display none

    .nav-items
        margin 0
        padding 0
        text-align right

    .nav-main .nav-item
        color white
        text-shadow 1px 1px 5px black
        text-transform uppercase
        display inline-block
        padding 0
        margin 0
        position relative
        &:first-child
            margin-left 0

        +below('m')
            margin 0 1em

        &--active,
        &.nav-item--has-children.nav-item--child-active
            color inherit
            background rgba(238,2,66,0.06)
            border-bottom 3px solid #ee0242

        &--has-children
            a:after
                content: "\f0dd";
                padding-left 10px
                top -3px
                position relative
                font-family: FontAwesome;
            ul.nav-sub li a:after
                content ""

        &:hover
            background rgba(238,2,66,0.06)
            border-bottom 3px solid #ee0242
            .nav-sub
                display block


    .nav-link
        display block
        color inherit
        font-weight 300
        letter-spacing 1px
        padding 2.21em 0.65em
        margin 0
        transition border-color 0.5s ease, background-color 0.5s ease
        border-bottom 3px solid transparent

    .nav-main .nav-sub
        padding 1em 0
        position absolute
        z-index 1
        text-align left
        padding-left 0
        display none
        left 0px
        line-height 0.5
        top 90px
        min-width 274px
        box-shadow 0 2px 6px rgba(0, 0, 0, .1)
        background rgba(238,2,66,0.90)
        color white
        .nav-item
            display block
            text-transform none
            margin 0
            border none
            &:hover
                border none


        .nav-link
            display block
            margin 0
            padding .8em 2em
            border none
            &--active
                border none
