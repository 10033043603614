body.oc-loading,
body.oc-loading *
    cursor wait !important

.stripe-loading-indicator
    height 5px
    background transparent
    position fixed
    top 0
    left 0
    width 100%
    overflow hidden
    z-index 2000

    .stripe,
    .stripe-loaded
        height 5px
        display block
        background primary-color
        position absolute
        box-shadow inset 0 1px 1px -1px #FFF, inset 0 -1px 1px -1px #FFF

    .stripe
        width 100%
        animation infinite-loader 60s linear

    .stripe-loaded
        width 0
        opacity 0

    &.loaded
        opacity 0
        transition opacity 0.4s linear
        transition-delay 0.3s

    &.loaded .stripe-loaded
        opacity 1
        transition width 0.3s linear
        width 100% !important

    &.hide
        display none

@keyframes infinite-loader {
    0% {
        width 0%
    }
    10% {
        width 42%
    }
    20% {
        width 63%
    }
    30% {
        width 78.75%
    }
    40% {
        width 88.59375%
    }
    50% {
        width 94.130859375%
    }
    60% {
        width 97.07244873046875%
    }
    70% {
        width 98.58920574188232%
    }
    80% {
        width 99.35943391174078%
    }
    90% {
        width 99.74755670045852%
    }
    100% {
        width 99.9423761471391%
    }
}