h2
  font-weight 300
  font-size 2.5rem

h5
  font-weight 400
  font-size 1rem

h6
  font-weight 300
  font-size 1rem