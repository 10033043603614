.skiplink-anchor
    display block !important
    border 0 none !important
    margin 0 !important
    padding 0 !important
    background transparent !important
    outline 0 none !important
    line-height 0 !important
    height 0 !important
    font-size 0 !important
    overflow hidden !important

.skiplinks
    width 0
    height 0
    padding 0
    margin 0
    li
        display inline

        a
            display block
            position absolute
            left -8000px
            top -8000px
            width 0
            height 0
            z-index 200
            &:focus
                position absolute
                background-color #000
                color #FFF
                display block
                font-size 1.3em
                height auto
                width auto
                top 0
                left 0
                line-height 1.2em
                font-weight normal
                padding .5em 1em
                z-index 10000000
                text-decoration none