.main
    lost-utility clearfix
    padding 4em 0
    color #444
    min-height 400px
    min-height 40vh

.content
    lost-column 2/3
    +below('m')
        lost-column 1/1
    &.full-width
        lost-column 1/1
    font-size 1rem
    line-height 1.7
    margin-top .5em
    p
        margin 0 0 1em
    h2
        margin-top 0

aside
    lost-column 1/3
    +below('m')
        lost-column 1/1
    h3
        color primary-color
        font-weight 300
        font-size 1.6rem
