.header
    line-height 1
    min-height 500px
    height 60vh
    color white
    text-transform uppercase
    position relative
    background url('lead.jpg') no-repeat center 45%/cover

    &--small .overlay
            background-color rgba(255,255,255,0.125)
            height 100%
            width 100%

.logo
    lost-column 4/12 3
    +below('m')
        lost-column 1/2 2
    +below('s')
        lost-column 2/3 2

    a
        color inherit
        display block

    img
        max-width 100%

.menu-bar
    &--line
        position absolute
        left 0
        top 0
        width 100%
        height 90px
        background rgba(255,255,255,0.33)
    .logo--inner
        text-align center
        background white
        padding 10px

.defaultpage-title
    width 100%
    position absolute
    bottom 25px
    z-index 5
    h1
        font-size 1.8em
        font-weight 500
        display inline-block
        padding 0.25em 0.5em
        background #1ab9ec
        margin-bottom 12px

.landingpage-title
    width 100%
    position absolute
    bottom 25px
    z-index 5
    h1
        font-size 1.8em
        font-weight 500
        display inline-block
        padding 0.25em 0.5em
        background #1ab9ec
        margin-bottom 12px
    span
        color #445487
        background white
        display inline-block
        padding 0.25em 0.75em
        font-size 1.4rem
        font-weight 500

.hero-stripe
    position absolute
    bottom 0
    height 118px
    margin-bottom -53px
    width 100%
    z-index 1
    background url('hero-stripe.png') no-repeat 25% 45%

.lang-selector
    position absolute
    right 0
    top 0
    font-weight 300
    letter-spacing 1px
    padding 2.21em 0.15em
    margin 0 1em
    lost-column 1/12 3
    +below('m')
        display none