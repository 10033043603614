.footer
    lost-utility clearfix
    background #445487
    padding 2em 0
    color #fff
    position relative
    .arrow-down
        border-top-color #1ab9ec
    strong
        display block
        width 100%
        font-weight 500
        padding-bottom 1em
    &--address
        lost-column 1/2
        +below('s')
            lost-column 1/1
            margin-bottom 1em
        .row div
            font-weight 300
            lost-column 1/2
            +below('s')
                margin-bottom 1em
                lost-column 1/1
    &--nav
        lost-column 1/2
        +below('s')
            lost-column 1/1
        ul
            margin 0
            padding 0
            list-style none
            li
                lost-column 1/4 4 10px
                +below('l')
                    lost-column 1/3 3
                +below('m')
                    lost-column 1/2 2
                a
                    color white
                    font-weight 300
                    padding-left 5px
                &:before
                    content: "\f105";
                    font-family: FontAwesome;
                    font-style: normal;
                    font-weight: normal;
                    text-decoration: inherit;

    a
        color #aaa