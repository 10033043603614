.reference
  &__block
    overflow auto
    clear both
    border 1px solid #eeeeee
    padding 1.5rem
    position relative
    transition transform 0.5s ease
    +.reference__block
      margin-top 2em
    &:active
      transform scale(.96)

    a
      color inherit

    &__title
      font-weight 700
      padding-bottom 0.20rem
      text-transform uppercase

    &__text
      font-size 0.8em
      display none
      &.reference-visible
        display block

    &__button
      position absolute
      background #ee0242
      color white
      font-weight 600
      font-size 0.9em
      text-transform uppercase
      padding 1px 15px
      right 0
      bottom 0